import React, { useState, useEffect } from 'react';
import { bool, shape, string, func, arrayOf } from 'prop-types';
import classnames from 'classnames';
import Snackbar from '@andes/snackbar';
import { Button } from '@andes/button';
import IconFactory from '../../icons/factory-builder';

import { validateSnackbarColor } from '../../../utils/validators';

const component = 'grouped_main_actions';
const DEFAULT_DELAY_TIME = 3000;
const namespace = 'ui-vip-action-contact-info';

const ContactInfoAction = ({
  // eslint-disable-next-line react/no-object-type-as-default-prop
  label = {},
  type,
  hierarchy,
  isFetchingData,
  fetchContactInfo,
  itemId,
  contactType,
  message,
  icon,
  recaptchav2token,
  ajax_backend_params: ajaxBackendParams,
  onClickHandler,
  doContactAction,
  is_login_required,
  redirectToLogin,
  featureName,
  recaptchaSiteKey,
  is_hide_modal,
}) => {
  const onContactInfoClickHandler = e => {
    if (e) {
      e.preventDefault();
    }
    if (onClickHandler) {
      onClickHandler(e);
    }
    if (is_login_required) {
      return (
        redirectToLogin &&
        redirectToLogin({
          itemId,
          featureName,
          loginType: 'vis_motor_contact',
        })
      );
    }

    return (
      fetchContactInfo &&
      fetchContactInfo({
        component,
        itemId,
        contactType,
        message,
        action: 'classi_contact',
        recaptchav2token,
        ajaxBackendParams,
        recaptchaSiteKey,
        params: { is_hide_modal },
      })
    );
  };
  const [buttonProps, setButtonProps] = useState({});
  const classNames = classnames(namespace, { [`${namespace}-${type}--disabled`]: isFetchingData === true });

  useEffect(() => {
    const newButtonProps = {
      ...buttonProps,
    };

    if (newButtonProps.isFetching === false) {
      newButtonProps.isFetchingComplete = !newButtonProps.isFetching;
    }

    if (isFetchingData !== null) {
      newButtonProps.isFetching = isFetchingData;
    }
    setButtonProps(newButtonProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingData]);

  useEffect(() => {
    const doFetch =
      doContactAction && doContactAction.length > 0 && doContactAction[0].origin === featureName && fetchContactInfo;
    if (doFetch) {
      fetchContactInfo({
        component,
        itemId,
        contactType,
        message,
        action: 'classi_contact',
        recaptchav2token,
        ajaxBackendParams,
        recaptchaSiteKey,
      });
    }
  });

  return (
    <>
      <Button
        className={classNames}
        hierarchy={hierarchy}
        disabled={isFetchingData}
        type={type}
        onClick={onContactInfoClickHandler}
        data-testid="button-contact-info"
        {...buttonProps}
      >
        {icon && IconFactory(icon)}
        {label && label.text}
      </Button>
      {message && message.text && (
        <Snackbar
          delay={DEFAULT_DELAY_TIME}
          message={message.text}
          key={message.key}
          color={validateSnackbarColor(message.error)}
          show={message.show}
        />
      )}
    </>
  );
};

ContactInfoAction.propTypes = {
  isFetchingData: bool,
  label: shape({
    text: string,
  }),
  disabled: bool.isRequired,
  type: string.isRequired,
  hierarchy: string.isRequired,
  itemId: string,
  contact_info: shape(),
  contactType: string,
  message: shape({
    messages: string,
    type: string,
  }),
  fetchContactInfo: func,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  recaptchav2token: string,
  recaptchaSiteKey: string,
  onClickHandler: func,
  ajax_backend_params: shape({
    track_source: string,
    track_from_view: string,
    track_event_source: string,
  }),
  doContactAction: arrayOf(
    shape({
      origin: string,
    }),
  ),
  is_login_required: bool,
  redirectToLogin: func,
  featureName: string.isRequired,
  is_hide_modal: bool,
};

ContactInfoAction.defaultProps = {
  isFetchingData: null,
  label: null,
  fetchContactInfo: null,
  itemId: null,
  contactType: '',
  message: null,
  contact_info: null,
  icon: null,
  recaptchav2token: null,
  recaptchaSiteKey: null,
  onClickHandler: null,
  ajax_backend_params: null,
  is_login_required: false,
  redirectToLogin: null,
  doContactAction: null,
  is_hide_modal: null,
};

export default ContactInfoAction;
